.stayLoggedin .form-check-label {
  cursor: pointer;
}
.stayLoggedin .form-check-label .MuiSvgIcon-root {
  margin-left: 5px;
  fill: #73818f;
}
.stayLoggedin .form-check-input {
  margin-top: 0.2rem !important;
  cursor: pointer;
}
.logginInfo {
  padding: 8px 3px;
}
.logginInfo h6 {
  background: #f3f4f5;
  padding: 10px;
  margin: -12px -11px 8px;
}
.logginInfo span {
  font-size: 13px;
  line-height: 1.39;
  font-weight: 400;
}
.stayLoggedin .MuiTooltip-tooltip {
  background-color: tomato;
}
