header.app-header.navbar.topHeader {
  background: rgb(6, 66, 166);

  height: 55px;
  border-bottom: 0px;

  left: 0;
  right: 0;
  margin: auto;
}
.topHeader img {
  max-width: 160px;
}
.topHeader .dropdown > button {
  background-color: #1e1d2e;
  padding: 0.25rem 1rem;
  color: #fff;
  border: 1px solid;
}
.topHeader .dropdown .dropdown-toggle::after {
  margin-left: 20px;
}
.adminSelectn {
  background-color: transparent;
  color: #fff;
  width: 140px;
}
.adminSelectn .Select-placeholder,
.Select.adminSelectn .Select-control .Select-value-label {
  color: #fff !important;
}
.adminSelectn .Select-arrow {
  border-color: #fff transparent transparent;
}
.Select.adminSelectn.is-open .Select-arrow {
  border-color: transparent transparent #fff !important;
}
.adminTabs li.nav-item a.nav-link {
  padding: 5px 15px;
}
.adminTabs li.nav-item a.nav-link:hover,
.userName li.nav-item a.nav-link:hover,
.userName li.nav-item a.nav-link span {
  color: #73818f;
}
.adminTabs li.nav-item a.nav-link.active {
  border: 1px solid #24a4cf;
  border-radius: 3px;
  color: #24a4cf;
}
.userName li.nav-item a.nav-link {
  color: #fff;
  font-weight: 600;
}
.userName li.nav-item a.nav-link span.nameIcon {
  color: #fff;
  padding: 6px 15px;
  background-color: #2d1e5e;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 3px;
}

.nav-Items {
  margin-left: 6%;
  font-size: 16px;
}

.profileDropdown .dropdown-item,
.notificationDropdown .dropdown-item {
  border: none;
}

.topHeader .navbar-nav .dropdown-menu-right {
  right: 0 !important;
}

.topHeader .navbar-dark a {
  color: #73818f;
}

.navbar-nav .active > .nav-link:hover {
  color: #73818f !important;
}
button.activeTab {
  color: #fff !important;
}
.campaign-title-nav {
  position: absolute;
  right: 0;
  left: 0;
  color: #fff;
}
.campaign-title-nav span {
  max-width: 400px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}
