// Here you can add other styles
html body .app.flex-row.align-items-center {
  height: 100vh;
}
body {
  // background-color: #fff;
  font-family: "Poppins", sans-serif !important;
  background-color: #fff;
}
.profileDropdown .dataLetters,
.notificationDropdown .notificationIcon {
  cursor: pointer;
}
.react-bootstrap-table table {
  table-layout: auto !important;
  margin-bottom: 2rem;
}

.actionBtn a i {
  color: #636363;
}
.actionBtn.btn-secondary.btn {
  background-color: transparent;
  padding: 0;
  font-size: 1.2rem;
}
.tableHeader {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #0a090c;
}
.tableHeader.modal-width-preview {
  font-weight: normal !important;
}
.jodit-workplace p {
  margin-bottom: 6px;
  font-size: 14px;
}

.fileContainer .upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.fileContainer .upload + label.uploadLabel {
  width: 200px;
  height: 40px;
  font-size: 1em;
  font-weight: 500;
  color: white;
  line-height: 2.6em;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(32, 168, 216);
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0px 3px 0px rgb(32, 168, 216);
  transition: 150ms;
}

.fileContainer .upload:focus + label.uploadLabel,
.fileContainer .upload + label.uploadLabel:hover {
  background-color: rgb(32, 168, 216);
  box-shadow: 0px 5px 0px rgb(32, 168, 216);
  cursor: pointer;
}

.fileContainer .upload:focus + label.uploadLabel {
  outline: 1px dotted rgb(32, 168, 216);
  outline: -webkit-focus-ring-color auto 5px;
}
.fileUploader {
  text-align: center;
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background: #4b4656;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}
input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
.Select-placeholder,
.form-control {
  color: #636363 !important;
  font-size: 13px !important;
}
.fileUploader input[type="file"] {
  overflow: hidden;
  display: inline-block;
  height: 0;
  width: 0;
}
.searchBtn {
  display: inline-block;
  position: relative;
}
.searchBtn i {
  position: absolute;
  right: 0;
  top: 0px;
  bottom: 0;
  margin: 0 auto;
  transform: translate(-10px, 10px);
}
button#pageDropDown {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.pagination li a.page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
}
span.react-bootstrap-table-pagination-total {
  font-size: 12px;
  color: #636363;
}
.media-body h5 {
  white-space: pre-wrap;
}
th {
  color: #0a090c !important;
}

td {
  color: #636363 !important;
  font-weight: normal !important;
}

label {
  color: #0a090c !important;
}

// .btn-outline-primary,
// .btn-outline-secondary {
//   color: #e40066 !important;
//   border-color: #e40066 !important;
// }

// .btn-outline-primary:hover,
// .btn-outline-secondary:hover {
//   color: #fff !important;
//   background-color: #e40066 !important;
//   border-color: #e40066 !important;
// }

// .btn-primary {
//   color: #fff;
//   background-color: #e40066 !important;
//   border-color: #e40066 !important;
// }

// color css start
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-primary.focus,
.btn-primary:focus,
.input-group-append .btn:focus,
.input-group-prepend .btn:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
.btn-outline-secondary {
  color: #007bff !important;
  border-color: #007bff !important;
}
.btn-link {
  color: #007bff !important;
}
.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
table a {
  color: #007bff !important;
}
.jodit-container table a.donateButtons {
  color: #fff !important;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
ul.pagination a.page-link {
  color: #007bff;
}
ul.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff !important;
}
.profileSideInfo ul li a {
  color: #0a090c !important;
}
.donorDonationsInfo a.nav-link {
  color: #495057 !important;
}
// color css end

footer.app-footer a,
footer.app-footer span {
  font-size: 14px;
}
footer.app-footer.no-sidebar-footer {
  margin-left: 0 !important;
}
.table thead th:focus {
  outline: none;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}
.react-bootstrap-table th.sortable {
  cursor: pointer;
}
.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}
.dropup,
.dropdown {
  position: relative;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
.react-bootstrap-table-pagination .dropdown-item a {
  display: block;
  padding: 5px 10px;
}
button#pageDropDown .caret {
  display: none;
}
.react-bootstrap-table-pagination .dropdown-item {
  padding: 0px;
}
.react-bootstrap-table-pagination .dropdown-menu {
  min-width: auto;
}
.btn-secondary.focus,
.btn-secondary:focus,
button {
  box-shadow: none !important;
  outline: none !important;
}
header.topHeader.app-header.navbar {
  padding: 0;
}
.navbar-dark .navbar-toggler {
  border-color: transparent !important;
}
.topHeader [data-letters]:before {
  margin-right: 0.5em;
  background: #cdcdcd;
  color: #333333;
}
.usdDropdown {
  padding: 10px;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}
.dropdown-menu .dropdown-item.profileName {
  font-size: 0.875rem;
  padding: 10px 10px;
}
.profileDropdown .dropdown-item i {
  width: auto;
}
.topHeader .navbar-dark a:active {
  color: #ffffff !important;
}
.profileDropdown .dropdown-item.profileName.active,
.profileDropdown .dropdown-item.profileName:active {
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}
.pagination {
  justify-content: flex-end;
}
.btn-group-sm > .btn,
.btn-sm,
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.roleForm label.form-check-label.form-check-label {
  font-weight: 400 !important;
}
.usdDropdown .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 16px) scale(1);
  pointer-events: none;
  font-size: 14px;
}
.usdDropdown .MuiOutlinedInput-root.MuiInputBase-formControl {
  padding: 0;
  padding-right: 0 !important;
  height: 40px;
}
.usdDropdown
  .MuiOutlinedInput-root.MuiInputBase-formControl
  input#combo-box-demo {
  padding: 5px 10px;
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.usdDropdown .MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75);
}
// .sidebar-minimized .usdDropdown {
//   display: none;
// }
.checkboxLabel {
  font-size: 14px;
}
.topOrgFilter {
  font-size: 14px !important;
}
.topHeader .navbar-dark a:focus {
  outline: none !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 241, 255, 0.3);
}

.uploadLabel {
  background-color: #007bff !important;
  color: #fff !important;
}
.backIcon {
  left: 0;
}
.btn-link.collapseBtn:hover {
  text-decoration: none;
  color: #0a090c;
}
.btn-link.collapseBtn,
.btn-link.collapseBtn:focus {
  text-decoration: none;
  // margin-bottom: 15px !important;
  color: #0a090c;
}
.btn-link.collapseBtn {
  text-decoration: none;
  // box-shadow: 0px 0px 13px 0px #eee !important;
  padding: 10px !important;
  background-color: #eee;
  color: #0a090c !important;
}
.collapseBtn h5,
.collapseBtn i {
  font-size: 14px;
}
.modal-dialog.modal-dialog-centered.adminOnboardPopup {
  max-width: 620px;
}
span.userDetail {
  padding: 10px 10px;
  display: inline-block;
  width: 100%;
}
#accordion ul li > ul li {
  box-shadow: 0px 0px 13px 0px #eee !important;
}
.dataLetters {
  display: inline-block;
  font-size: 1em;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  background: #4b4656;
  vertical-align: middle;
  margin-right: 10px;
  color: white;
  margin-bottom: 0px;
  cursor: default;
  font-weight: 600;
}

// .onboardForm input.form-control:focus,
// .onboardForm select.form-control:focus {
//   outline: none;
//   box-shadow: none;
//   border-color: #ced4da;
// }
.fileContainer {
  background: #fff;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto !important;
  transition: all 0.3s ease-in;
}
.causeForm .input-group-text {
  font-size: 0.85rem;
}
.modal-title {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: #0a090c;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.table td,
.table th {
  padding: 0.2rem !important;
  border: 0 !important;
  border-top: 1px solid #dee2e6 !important;
}
.table-bordered {
  border: none !important;
}
.spinner {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 5px;
  transform: translate(7px, 0px);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 241, 255, 0.3) !important;
}
.reportTable .DateInput_input {
  height: 33px;
  font-size: 12px;
  font-weight: 400;
  margin-left: 2px;
  border: 0px;
  color: #adadad;
  padding: 10px;
}
.reportTable .DateRangePicker,
.reportTable .DateRangePickerInput__withBorder {
  width: 200px;
  height: 35px;
  border-radius: 0.25rem;
}
.reportTable .DateInput {
  width: 90px;
}
.reportTable .DateRangePickerInput_arrow_svg {
  height: 14px;
  width: 14px;
}
.profileDropdown .dropdown-divider,
.notificationDropdown .dropdown-divider {
  margin: 0;
}

.proDetailWrap span.dataLetters {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1rem;
  margin-right: 10px;
  cursor: default;
}

.profileDropdown a.dropdown-item {
  color: #636363 !important;
  font-size: 14px !important;
  padding: 10px 20px;
  cursor: pointer;
}
.profileDropdown .dropdown-menu {
  padding: 0;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border: 0px;
}
.notificationDropdown .dropdown-menu {
  padding: 0;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border: 0px;
  border-radius: 4px;
}
.noti_head {
  padding: 1rem 1rem;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
span.noti_title {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  color: #0642a6;
  margin: 0;
  padding: 0;
}
span.noti_sub {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  right: 0;
  left: 0;
  height: 2.8rem;
  bottom: -2rem;
}
span.noti_desc {
  color: #74788d;
  font-size: 0.7rem;
  font-weight: 400;
  background: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border-radius: 3px;
}
.notificationsDetail {
  margin-top: 40px;
  height: 220px;
  overflow-y: auto;
  border-top: 1px solid #e9ecef;
}
.notification_details {
  color: #636363;
}
.notification_title {
  font-size: 0.8rem;
  font-weight: 400;
}
.notification_time {
  font-size: 0.6rem;
  font-weight: 400;
}
.notification_icon {
  display: flex;
  align-items: center;
}
.notificationsDetail a.dropdown-item {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #f7f8fa;
}
.notificationsDetail a.dropdown-item:active .notification_details {
  color: #fff !important;
}
// Color Classes Start
.bg-red {
  background-color: #f44336 !important;
}
.bg-pink {
  background-color: #e91e63 !important;
}
.bg-purple {
  background-color: #9c27b0 !important;
}
.bg-deepPurple {
  background-color: #673ab7 !important;
}
.bg-indigo {
  background-color: #3f51b5 !important;
}
.bg-blue {
  background-color: #2196f3 !important;
}
.bg-cyan {
  background-color: #00bcd4 !important;
}
.bg-teal {
  background-color: #009688 !important;
}
.bg-green {
  background-color: #4caf50 !important;
}
.bg-lime {
  background-color: #cddc39 !important;
}
.bg-amber {
  background-color: #ffc107 !important;
}
.bg-orange {
  background-color: #ff9800 !important;
}
.bg-deepOrange {
  background-color: #ff5722 !important;
}
.bg-blueGray {
  background-color: #607d8b !important;
}
.bg-gray {
  background-color: #9e9e9e !important;
}
.bg-darkGray {
  background-color: #616161 !important;
}
.bg-indigoDarken {
  background-color: #1a237e !important;
}
.bg-tealDarken {
  background-color: #004d40 !important;
}
.bg-limeDarken {
  background-color: #827717 !important;
}
.bg-tealAccent {
  background-color: #1dc9b7 !important;
}
.bg-redLighten {
  background-color: #e57373 !important;
}
.bg-brownDarken {
  background-color: #3e2723 !important;
}
.bg-maroon {
  background-color: #4d0000 !important;
}
.bg-magenta {
  background-color: #ff00ff !important;
}
// Color Classes End
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}
.profileDropdown .dataLetters {
  background-color: #dc3444;
  font-weight: 600;
  cursor: pointer;
}
.notificationDropdown .badge-pill {
  cursor: default;
}
.dropdown-menu {
  font-size: 13px !important;
}
.statusBtn .casueStatus:focus {
  outline: 0;
  box-shadow: 1px 1px 13px 2px #eee;
}
.profileSideInfo ul li a.activeProfileLink {
  background-color: rgba(85, 120, 235, 0.05);
  color: #007bff !important;
}
.notiCard,
.taskCard,
.recentActCard,
.causeStatsCard {
  // box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.17);
  border: 1px solid #c8ced3;
  border-radius: 3px;
}
.notiCard a.nav-link.active,
.taskCard a.nav-link.active,
.causeStatsCard a.nav-link.active {
  border-bottom: 3px solid #007bff;
  color: #007bff !important;
}
h3.notiCard_title,
.taskCard_title,
.causeStatsCard_title {
  font-size: 16px;
  font-weight: 500;
  color: #48465b;
  padding: 15px;
  margin-bottom: 0;
}
.notiCard a.nav-link,
.taskCard a.nav-link,
.causeStatsCard a.nav-link {
  font-size: 13px;
  padding: 7px;
  border: 0px;
  color: #636363 !important;
}

.notiCard .nav-tabs .nav-item,
.taskCard .nav-tabs .nav-item,
.causeStatsCard .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.notiCard .tab-content,
.taskCard .tab-content,
.causeStatsCard .tab-content {
  border-top: 1px solid #c8ced3;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}
.notiCard_icon,
.taskCard_icon,
.causeStatsCard_icon {
  flex-direction: column;
  justify-content: center;
  flex: 0 0 2.5rem;
}
.notiCard_text,
.taskCard_text,
.causeStatsCard_text {
  flex-direction: column;
  flex-grow: 1;
}
.notiCard_text a,
.taskCard_text a,
.causeStatsCard_text a {
  color: #636363 !important;
}
.notiCard_datetime,
.taskCard_datetime,
.causeStatsCard_datetime {
  color: #a5a5a5;
}
.notiCard_details,
.taskCard_details,
.causeStatsCard_details {
  padding: 10px;
  border-bottom: 1px solid #f7f8fa;
}
.notiCard_body .tab-pane .notiCard_details:last-child,
.taskCard_body .tab-pane .taskCard_details:last-child,
.causeStatsCard_body .tab-pane .causeStatsCard_details:last-child {
  border-bottom: 0px;
}
.taskCard_icon {
  position: relative;
}
.taskCard_icon:before {
  position: absolute;
  display: block;
  width: 0.2rem;
  height: 100%;
  top: 0.45rem;
  height: calc(100% - 0.8rem);
  content: "";
  border-radius: 0.2rem;
  background-color: #0642a6;
}
.taskCard_checkbox {
  flex: 0 0 1.5rem;
}
.recentActCard_label {
  font-size: 16px;
  font-weight: 500;
  color: #48465b;
  padding: 15px;
}

.recentActArrow a.carousel-control-prev {
  width: auto;
  top: 0;
  display: inline-block;
  right: 20px;
  left: auto;
}
.recentActArrow a.carousel-control-next {
  width: auto;
  top: 0;
  display: inline-block;
  right: 0px;
  left: auto;
}
.recentActArrow {
  flex: 1;
}
a.recentActCard_title {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin-right: 75px;
}
span.recentActCard_desc {
  font-size: 13px;
  color: #63636394;
  width: 85px;
  text-align: right;
  font-style: italic;
  font-weight: 400;
  margin-left: -85px;
}
.recentActCard_msg {
  background-color: #fafafa;
  margin-bottom: 7px;
  padding: 10px 7px;
}
.recentActCard_body {
  padding: 15px;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: relative;
}
.recentActCard_head {
  border-bottom: 1px solid #c8ced3;
}
.cardShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border: 0;
}
input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
}
.bgLight {
  background-color: rgb(250, 251, 252);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 368px, 368px;
  background-position: left bottom -40px, right bottom -85px;
}
.CalendarDay__blocked_out_of_range {
  background-color: #f7f3f3 !important;
}
span.causeStatsCard_school {
  color: #636363;
}
.causeStatsCard_text h5 {
  font-size: 14px;
}
.dropdown-item.active i,
.dropdown-item:active i {
  color: #fff;
}
label.uploadLabel:hover {
  background-color: #0069d9 !important;
}
.onboardForm .Select--multi .Select-value {
  //height: 20px;
  //vertical-align: middle;
  // line-height: 14px;
  font-size: 12px;
  display: inline-block;
}
.onboardForm .Select--multi.has-value .Select-input {
  height: 20px;
  font-size: 12px;
  line-height: 15px;
}
// .onboardForm .Select--multi .Select-multi-value-wrapper {
//   padding-bottom: 5px;
// }
input#selectUsdAdmin {
  padding-top: 5px;
  padding-bottom: 0px;
}
.row.react-bootstrap-table-pagination ul.dropdown-menu.show {
  padding: 0;
}
.causeForm label,
.onboardForm label,
.userForm label,
.roleForm label,
.financeForm label,
.row-expansion-style label {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.2px !important;
}
.formMiddleHeading {
  font-size: 14px;
  font-weight: 500;
  color: rgb(72, 70, 91);
}
.btn {
  font-size: 0.813rem !important ;
}
.cardHeaderLft a {
  font-size: 13px;
  color: #007bff !important;
}
.checkboxDisabled {
  color: #ccc !important;
  border-color: #ccc !important;
}
sup.notiCount {
  top: -1em;
  background-color: #dc3443;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 11px;
  display: inline-block;
  line-height: 15px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.__react_component_tooltip {
  display: inline-block;
  max-width: 390px;
  font-size: 12px;
}
.table-striped tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}
.financeForm .input-group-text {
  font-size: 0.85rem;
}
.tableHeader .backIcon {
  display: inline-block;
}
.readMoreText {
  font-size: 10px;
}
.spinner {
  right: -3px;
}
.adminOnboardCont {
  max-height: 250px;
  overflow: auto;
}
.tableHeader .modal-title {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #0a090c;
}
.ackStatus {
  width: 95px !important;
  margin: 0 auto;
  height: 25px !important;
  padding: 0 !important;
}
.causeForm .spinner {
  top: 34px;
  transform: translate(0px, 0px);
}
.causeForm .spinner svg {
  max-width: 15px;
}
span.linkCopied {
  top: -30px;
  transform: translate(-10px, -10px);
  font-size: 13px;
  background-color: #000;
  padding: 5px 10px;
  color: #fff;
}
.linkCopied:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  content: "";
}
.linkCopied:after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
  position: absolute;
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
}
.onboardForm .Select-control .select-menu-outer {
  z-index: 10;
}
.select-menu-outer .Select-option {
  font-size: 13px !important;
}
.onboardForm .switch-label .switch-slider:after {
  z-index: 0;
}
.onboardForm .switch-label .switch-slider:before {
  z-index: 0;
}
.adminOnboardCont label {
  font-size: 13px;
}
.dropdown.show {
  display: inline-block;
}
.expand-cell i {
  cursor: pointer;
}
.form-control.is-invalid {
  background-image: none !important;
}
.dashCardMinHeight {
  min-height: 160px;
  max-height: 160px;
}
.causeStatsCard .nav-tabs,
.taskCard_tabs .nav-tabs,
.notiCard_tabs .nav-tabs {
  border-bottom: 0px;
  padding: 0 15px;
}
.causeStatsCard_amount,
.causeStatsCard_flag {
  font-size: 14px;
}
.notiCard_details a {
  display: flex;
  width: 100%;
}
.notiCard_details a:hover {
  text-decoration: none;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545 !important;
}
span.dash4BoxText {
  font-size: 14px;
}
// .bg-primary.dashCardMinHeight.card {
//   background-color: #20a8d8 !important;
// }
.sidebar-minimized .sidebar .nav-link:hover {
  width: auto;
}
.Select--multi .Select-value-label {
  font-size: 13px;
}
.adminOnboardCont p.form-check-label {
  font-size: 13px;
}
.progress-xs.progress {
  height: 4px;
}
label.search-label {
  margin-bottom: 0;
}
button.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
  display: none;
}
.modal-dialog.causePreviewBox.modal-dialog-centered {
  max-width: 90%;
}
.causePreviewBox .modal-body {
  padding: 0px;
}
.causePreHeader {
  // height: 110px;
  padding: 0px 40px;
}
.causePreBottomBar {
  height: 50px;
  background-color: #646464;
}
.causePreHeader img {
  max-width: 180px;
}
.previewBody {
  padding: 30px;
}
.causePreTitle h3 {
  font-family: "Muli", sans-serif;
  font-size: 38px;
  color: black;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0;
  padding: 0px 0px 30px;
}
.causePreDesc p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
}
.causePreImg {
  display: block;
  position: relative;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.causeGoal span,
.causeRaised span,
.causeJoyfullBox span {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 800;
  font-family: Muli;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.causeAmountBtns button {
  padding: 3px 3px;
  background-color: #fff;
  color: rgb(0, 165, 207);
  border: 1px solid rgb(0, 165, 207);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.causeInputBtn .input-prepend {
  max-width: 60px;
}
.causeInputBtn .input-group-prepend {
  position: absolute;
  z-index: 12;
  transform: translate(0px, 9px);
}
.causeInputBtn input {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 100% !important;
  margin: 0 0 0px 0;
  border-radius: 0px;
}
.causeInputBtn span.input-group-text {
  padding: 0;
  border: 0;
  background: none;
  color: #0000008a;
  font-weight: 400;
  font-size: 15px;
}
.causeGoal,
.causeRaised,
.causeJoyfullBox {
  padding: 10px 3px !important;
}
.causePreAmount {
  padding: 5px 0px;
}
.causeInputBtn button {
  background-color: #00a5cf;
  color: #fff;
  border-radius: 20px;
  border-color: #00a5cf;
}
.causeGoal::after,
.causeRaised::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: #d1d1d1;
  right: 2px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.causeRaised::after {
  right: 5px;
}
.causePreImageBox {
  flex-direction: column;
  border: 2px solid #d1d1d1;
}
.causePreDetail {
  flex-direction: column;
}
.svgIcon {
  fill: currentColor;
  width: 17px;
  height: 17px;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: #00a5cf;
}
.causePreDonations .latestDonate {
  font-family: "Muli", sans-serif;
  font-size: 12px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  float: left;
}
.causePreDonations .donorNumber {
  font-family: "Muli", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  float: right;
}
.causePreDonations {
  padding: 20px 15px 0;
}
.causePreDonateTwo > ul {
  margin: 40px 0px 0 0;
  padding: 0;
}
.causePreDonateTwo ul li {
  list-style: none;
  width: 33.333%;
  display: inline-block;
  padding-bottom: 30px;
}
.causePreDonateTwo .donorName {
  padding: 0 0 10px 0;
  display: block;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
}
.causePreDonateTwo .donorAmount ul {
  padding: 0 7px;
}
.causePreDonateTwo ul li .donorAmtTitle {
  background-color: #f8f8f8;
  padding: 10px 3px;
  font-family: "Muli", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  width: 50%;
  box-sizing: border-box;
}
.causePreDonateTwo ul li .donorAmountNumber {
  background-color: #def3f9;
  padding: 8px 9px;
  font-family: "Muli", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #000;
  position: relative;
  width: 50%;
  box-sizing: border-box;
  text-align: center;
}
.causePreDonateTwo .donorAmountNumber::before {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  left: -24px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-bottom-color: #def3f9;
  content: "";
  transform: rotate(270deg);
}
.causeSocialIcon {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  border-radius: 0 18px 0 0;
  z-index: 9;
  display: inline-block;
  padding: 5px 10px;
}
.causeSocialIcon a {
  color: #909090 !important;
}
.causePreProgress .CircularProgressbar-path {
  stroke: #00a5cf !important;
}
.causePreProgress .CircularProgressbar-trail {
  fill: #fff !important;
  stroke: #eee !important;
}
.causePreProgress {
  width: 80px;
  justify-content: space-around;
  position: absolute;
  z-index: 99;
  right: 20px;
  bottom: -20px;
}
.CircularProgressbar-background {
  fill: green;
}

// checkbox css

@keyframes click-wave {
  0% {
    height: 20px;
    width: 20px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 40px;
    width: 40px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0;
  }
}

.roleForm input.form-check-input[type="checkbox"],
input.customCheckbox[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 16px;
  width: 16px;
  transition: all 0.15s ease-out 0s;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  z-index: 0;
  margin-top: 0px;
  margin-bottom: 15px;
  border-radius: 2px;
}

.roleForm input.form-check-input[type="checkbox"] + label,
input.customCheckbox[type="checkbox"] + label {
  margin-bottom: 15px;
}
.roleForm input.form-check-input[type="checkbox"]:hover,
input.customCheckbox[type="checkbox"]:hover {
  background: #fff;
}
.roleForm input.form-check-input[type="checkbox"]:checked,
input.customCheckbox[type="checkbox"]:checked {
  background: #167bff;
  border: 2px solid #167bff;
}
.roleForm input.form-check-input[type="checkbox"]:checked::before,
input.customCheckbox[type="checkbox"]:checked::before {
  height: 15px;
  width: 15px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
  left: -1px;
  top: 1px;
  font-weight: 900;
}
.roleForm input.form-check-input[type="checkbox"]:checked::after,
input.customCheckbox[type="checkbox"]:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #167bff;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}
input.customCheckbox[type="checkbox"] {
  top: 7px;
}
// checkbox css end

/* .MuiAutocomplete-popper {
  position: fixed !important;
  transform: translate3d(10px, 111px, 0px) !important;
} */
.MuiAutocomplete-paper {
  margin: 0px;
}

.progress {
  background-color: #b3b9be !important;
}
ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}
.bgDark {
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  position: relative;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.userForm .react-tel-input .form-control {
  width: 100% !important;
}
.onboardForm .is-invalid .Select-control {
  border-color: #dc3545;
}
.userForm label.form-check-label.form-check-label {
  font-weight: 400 !important;
  width: calc(100% - 16px);
}
.Select-menu-outer {
  font-size: 13px !important;
}
.dateRangePick.is-invalid .DateRangePickerInput__withBorder {
  border-color: #dc3545;
}
.dateRangePick .DateInput {
  background: transparent;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 13px !important;
}

.single-chart {
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: #fff;
  stroke: #eee;
  stroke-width: 5;
}

.circle {
  fill: none;
  stroke-width: 5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
  background: #fff;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: #00a5cf;
}
.percentage {
  fill: #8dbffb;
  font-size: 0.625rem;
  font-size: 0.5rem;
  font-weight: bold;
  text-anchor: middle;
}
.onboardForm .Select-value .Select-value-icon,
.causeForm .Select-value .Select-value-icon {
  display: table-cell;
  vertical-align: middle;
}
.onboardForm .Select-value .Select-value-label,
.causeForm .Select-value .Select-value-label {
  display: table-cell;
  vertical-align: middle;
}
.causeForm .Select-value {
  display: inline-block;
}
.causeStatsCard_scroll {
  height: 380px;
  overflow: auto;
}
.roleForm input.form-check-input[type="checkbox"]:focus,
input.customCheckbox[type="checkbox"]:focus {
  // border-color: #80bdff;
  outline: 0;
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.ql-container.ql-snow {
  height: 250px;
  overflow: auto;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.causeTable2Step2 .react-bootstrap-table {
  overflow: auto;
  height: 211px;
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
}
.causeTable2Step2 .react-bootstrap-table::-webkit-scrollbar {
  display: none;
}

.causeTable2Step2 table {
  margin: 0px;
}
.fileContainer.is-invalid {
  border-color: #dc3545 !important;
}
.quill.is-invalid .ql-toolbar.ql-snow {
  border-top-color: #dc3545;
  border-right-color: #dc3545;
  border-left-color: #dc3545;
}
.quill.is-invalid .ql-container.ql-snow {
  border-bottom-color: #dc3545;
  border-right-color: #dc3545;
  border-left-color: #dc3545;
}

.switch.switchBox {
  cursor: pointer;
  height: 18px;
  margin-bottom: 0px;
  input {
    display: none;
    & + span {
      width: 35px;
      height: 18px;
      border-radius: 14px;
      transition: all 0.3s ease;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      position: relative;
      background: #b3b9be;
      //box-shadow: 0 8px 16px -1px rgba($red, 0.2);
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        transition: all 0.3s ease;
      }
      &:before {
        top: 2px;
        left: 3px;
        width: 14px;
        height: 14px;
        border-radius: 9px;
        border: 3px solid #fff;
        background: #fff;
      }
      &:after {
        top: 2px;
        left: 25px;
        width: 4px;
        height: 14px;
        border-radius: 40%;
        transform-origin: 50% 50%;
        background: #fff;
        opacity: 0;
      }
      &:active {
        transform: scale(0.92);
      }
    }
    &:checked {
      & + span {
        background: #007bff;
        box-shadow: 0 8px 16px -1px rgba($green, 0.2);
        &:before {
          margin-left: 15px;
          border-width: 2px;
          background: #fff;
          width: 14px;
          height: 14px;
          border-radius: 9px;
          border: 3px solid #fff;
        }
        &:after {
          animation: blobChecked 0.35s linear forwards 0.2s;
        }
      }
    }
    &:not(:checked) {
      & + span {
        &:before {
          animation: blob 0.85s linear forwards 0.2s;
        }
      }
    }
  }
}

@keyframes blob {
  0%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.12, 0.94);
  }
  60% {
    transform: scale(0.96, 1.06);
  }
}

@keyframes blobChecked {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(1.44);
  }
  70% {
    transform: scaleX(1.18);
  }
  50%,
  99% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}
.donorDetails .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}
.donorDetails input#outlined-disabled,
.donorDetails input#outlined-disabled1,
.donorDetails input#outlined-disabled2,
.donorDetails input#outlined-disabled3,
.donorDetails input#outlined-disabled4,
.donorDetails input#outlined-disabled5,
.donorDetails input#outlined-disabled6 {
  padding: 8px 13px;
  font-size: 13px;
}
.resetPassDialog input#outlined-disabled {
  padding: 10px 14px;
}
.resetPassDialog .MuiFormControl-root.MuiTextField-root {
  width: 80%;
  margin: auto;
  display: block;
}
.resetPassDialog .MuiInputBase-root {
  width: 100%;
}
main.main {
  border-left: 1px solid #ccc;
}
main.main.no-sidebar {
  border-left: 1px solid #ccc;
  margin-left: 0 !important;
}
.dropdownForTabs {
  background-color: #ebf0f7;
  padding: 0px 15px;
}
.dropdownForTabs select#select-tab {
  width: 156px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
}
.dropdownForTabs span,
.dropdownForTabs label {
  font-size: 13px;
  font-weight: 500;
  color: #0a090c;
}
.donorDetails .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}
.donorDetails label {
  font-size: 12px;
}
.fileUploadBox span,
.fileUploadBox p {
  font-size: 12px;
  font-weight: 600;
  color: #959596;
}
.fileUploader button {
  font-size: 12px;
  font-weight: 600;
}
.cropImagePreview .modal-content {
  height: 600px !important;
  width: 100% !important;
  margin: auto !important;
  max-width: 100% !important;
}
.control-slider {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  padding: 0 16px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: white;
}
.slider {
  padding: 15px 0px;
}
.fileImage img {
  position: relative;
}
.fileImage img:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.fileImage {
  position: relative;
}

.fileImage img {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.fileImage .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  height: 90%;
  padding: 0 15px;
  background-color: #000;
}

.fileImage:hover .middle {
  opacity: 0.8;
}

.fileImage .text {
  color: white;
  font-size: 13px;
  padding: 16px 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  cursor: pointer;
}
.fileViewBox {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
}
span.closeImg {
  position: absolute;
  top: -15px;
  right: 10px;
  cursor: pointer;
}
.flagCause {
  font-size: 13px;
}
.causeCardDetails {
  padding: 20px;
  border-bottom: 1px solid #f7f8fa;
}
.causeHeadTitle {
  font-weight: 600;
  display: block;
}
.causeOrgName {
  color: #646464;
  font-weight: 500;
  display: block;
  font-size: 13px;
}
.causeStartEndDate {
  display: block;
  font-size: 10px;
  color: #9e9e9e;
  font-style: italic;
}
.causeGoalRaise {
  font-size: 13px;
  display: block;
  font-weight: 600;
  color: #646464;
}
.causePrice {
  display: block;
  color: #9e9e9e;
  font-size: 13px;
}
.causeStatsCard_head {
  font-size: 13px;
  font-weight: 600;
  padding: 10px;
}
.causeCard_title {
  color: #262626;
}
.causeCard_duration {
  color: #9e9e9e;
}
.readMore {
  color: #007bff;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
}
.causeOrgTitle {
  font-size: 13px;
  font-weight: 600;
  color: #646464;
}
.causeCardDetails img {
  height: 70px;
  max-height: 70px !important;
  min-width: 118px !important;
  object-fit: cover;
}
.taskCard_text,
.notiCard_text {
  font-size: 13px;
}
.react-bootstrap-table table tr {
  height: 42px !important;
}
.financeFilter,
.batchtableFilter,
.bulkUploadFilter {
  display: flex;
  flex-direction: row;
  height: 55px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-bottom: 0px;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.clubForm label {
  font-size: 13px;
}
.causeStatsWrap {
  max-height: 590px;
  overflow: auto;
}
.headingAbsolute {
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.inputFileBatch input.form-control-file {
  font-size: 13px;
}
.invalidFile {
  border: 1px solid #f86c6b;
  border-radius: 3px;
}
.refreshBtn {
  cursor: pointer;
  width: 15px;
}
.invalidFile.is-invalid ~ .invalid-feedback {
  display: block;
}
.secondDropdown {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 20px;
}
.secondDropdown label {
  display: inline-block;
}
.secondDropdown .Select {
  width: 120px;
  display: inline-block;
  vertical-align: middle;
}
.secondDropdown .Select-control {
  height: 38px;
  border-color: #b5b8be;
  background-color: transparent;
  width: 100%;
}
.secondDropdown span.Select-arrow {
  border-color: #0a090c transparent transparent;
}
.fetchBtn {
  height: 33px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5rem;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.websiteLogo img {
  max-width: 120px;
  max-height: 100%;
  width: 120px;
  border-radius: 6px;
}
.websiteLogo {
  overflow: auto;
  height: 320px;
}
.websiteLogo .spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fetchedLogo {
  max-width: 50%;
}
.websiteLogo [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.websiteLogo [type="radio"] + img {
  cursor: pointer;
}
.websiteLogo label {
  position: relative;
  width: 120px;
  height: 120px;
  box-shadow: 0px 0px 14px 0px #ccc;
  justify-content: center;
  align-items: center;
  display: flex;
}
.websiteLogo [type="radio"]:checked + img + span {
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center center;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border-radius: 6px;
}
.wordLength {
  font-size: 12px;
}
.roleForm .form-check-inline .form-check-input {
  position: relative;
}
.roleForm .form-group svg.MuiSvgIcon-root,
.userForm .form-group svg.MuiSvgIcon-root,
.onboardForm .form-group .form-check svg.MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}
.clubForm p {
  font-size: 12px;
  font-weight: 600;
  color: #959596;
  margin-top: 10px;
}
.app-header .navbar-brand {
  height: auto;
}
td.react-bs-table-no-data {
  text-align: center;
}
.userForm input.customCheckbox[type="checkbox"] {
  top: 2px;
}
.clubList .checkbox.col-md-3.form-check:nth-child(4n + 1) {
  clear: left;
}
.causeStatWrap {
  max-height: 530px;
  overflow: auto;
}
.userForm .switch.switch-label.form-check-label {
  width: 48px;
}
.dropdownForTabs .MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  padding-right: 32px !important;
  font-size: 14px;
}
.taskCard_body,
.notiCard_body {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: relative;
}
.taskCard_body:after,
.notiCard_body:after,
.recentActCard_body:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.taskCard_tabs .nav-tabs,
.notiCard_tabs .nav-tabs {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: relative;
}
.taskCard_tabs .nav-tabs:after,
.notiCard_tabs .nav-tabs:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.helpingText {
  font-size: 10px;
  color: #a8a8a8;
}
.modalHeader {
  justify-content: center;
}
.modalHeader .modal-title {
  font-size: 16px !important;
}
.modelBody {
  padding: 15px 35px 25px;
}
.addContactAction {
  width: 165px;
  text-align: left !important;
  background: #fff;
  color: #636363 !important;
  border: 1px solid #ced4da !important;
  background: #fff !important;
}
// .table tr:last-child td .dropdown-menu[x-placement^="top"] {
//   top: auto;
//   bottom: 25px;
// }
.table tr td .dropdown-menu {
  left: -90px !important;
}
.table-responsive,
.react-bootstrap-table.responsiveTable {
  min-height: 300px;
}

.nosidebar-main {
  width: 80% !important;
  margin: 0 auto !important;
}
.table th {
  border-top: 1px solid #dee2e6 !important;
}

._loading_overlay_overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}
label.search-label {
  float: inherit;
  margin-bottom: 5px;
}
.reportTable label.search-label {
  margin-bottom: 5px;
}
.clubtableFilter label.search-label {
  margin-bottom: 0;
}
.search-label input {
  position: relative;
  background-image: url(/search.svg);
  background-size: 19px;
  background-position: center left;
  background-repeat: no-repeat;
  background-position: 96% 50%;
  padding-right: 30px;
}
.commonFilterSection {
  position: absolute !important;
  text-align: right;
  top: 45px;
}
.reportFilters .commonFilterSection {
  top: 51px;
  right: 0;
}
.commonTitleHeight {
  height: 45px;
}
.customDisable {
  opacity: 0.5;
  cursor: no-drop !important;
}
.bg-info.dashCardMinHeight {
  background-color: #63c2de !important;
}
.bg-primary.dashCardMinHeight {
  background-color: #1a92cb !important;
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  canvas {
    height: 50px !important;
  }
  span.dash4BoxText {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .mobile-none {
    display: none !important;
  }
  header.topHeader.app-header.navbar {
    position: fixed;
  }
  .contextFilter {
    padding: 10px;
  }
  .app-header .navbar-brand {
    left: 0;
    right: 0;
    margin: auto;
    width: 130px !important;
  }
  .causeTable .causetableFilter,
  .userTable .usertableFilter,
  .onboardOrgTable .onboardOrgFilter {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  .causetableFilter .col-md-3,
  .causetableFilter .col-md-9,
  .usertableFilter .col-md-3,
  .usertableFilter .col-md-9 {
    text-align: center !important;
    margin-bottom: 10px;
  }

  .dropdownForTabs .selectedOrgName {
    padding: 0;
  }
  .dropdownForTabs .orgNameDropdown {
    padding: 0;
  }
  .dropdownForTabs {
    padding: 10px;
  }
  .tableHeader {
    width: 100%;
  }
  .userForm .userTitle.mb-5,
  .causeTitleBox.mb-5 {
    margin-bottom: 10px !important;
  }
  .userForm,
  .causeForm {
    margin-top: 20px !important;
  }
  input.customCheckbox[type="checkbox"] {
    margin-right: 0;
  }
  .fileContainer {
    padding: 10px;
  }
  .onboardOrgFilter .cardHeaderLft {
    margin-bottom: 10px;
  }
  .orgTitleBox button {
    margin-bottom: 10px;
  }
  .responsiveTable {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media only screen and (max-width: 576px) {
  .financeFilter {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  .searchBtn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 568px) {
  .tableHeader {
    font-size: 1rem;
    font-weight: 500;
    color: #0a090c;
  }
  button.btn-sm.btn.btn-outline-primary {
    font-size: 0.7rem;
  }

  .row.react-bootstrap-table-pagination .col-xs-6.col-sm-6 {
    width: 50%;
  }
  span.react-bootstrap-table-pagination-total {
    font-size: 10px;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .roleTable .tableFilter {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
}
@media only screen and (max-width: 429px) {
  .onboardStatusFilter.mr-3.align-middle {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
  .causetableFilter .createNewCause,
  .usertableFilter .createNewUser,
  .onboardOrgFilter .orgTitleBox {
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .statusFilter.mr-3.align-middle,
  .usrStatusFilter.align-middle.mr-3 {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
  text-align: center;
  font-size: 11.5px;
}
.MuiButton-label {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #007bff !important;
}
.MuiSvgIcon-root {
  width: 18px !important;
  height: 18px !important;
}
.searchBtn input {
  padding-right: 36px;
  max-width: 187px;
}
.react-bootstrap-table-pagination {
  position: relative;
}
.showing {
  position: absolute;
  right: -50px;
  top: 5px;
}
.perPage {
  margin-left: 10px;
  vertical-align: middle;
}
