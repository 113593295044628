footer.app-footer {
  padding: 14px 20px;
}
.contentWrap {
  background-color: #fff;
  padding: 10px 20px 20px 20px;
  -webkit-box-shadow: 0 0 13px 0 rgba(45, 92, 145, 0.13);
  box-shadow: 0 0 13px 0 rgba(45, 92, 145, 0.13);
}
.header-fixed .contentWrap .app-body {
  margin-top: 60px;
}
.contentWrap header.app-header.navbar {
  border-bottom: 0px;
}

/* Custom css for sidebar */
.mainContent .sidebar {
  background-color: #ebf0f7;
  padding-top: 3px;
}

.orgDropdownSidebar {
  background: #1e1d2e;
  border: none;
  color: #fff;
  width: 100%;
  margin-top: 10px;
}

.z-head .z-head__title {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
}

.z-head .z-head__sub {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  right: 0;
  left: 0;
  height: 2.8rem;
  bottom: -1.4rem;
}

.z-head .z-head__sub .z-head__desc {
  color: #74788d;
  font-size: 1rem;
  font-weight: 400;
  background: #fff;
  text-align: center;
  padding: 0.75rem 1rem;
  -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border-radius: 3px;
}

.z-head {
  position: relative;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1rem 1rem;
  min-height: 100px;
  background-color: #24a4cf;
}

.z-dropdownItems {
  max-height: 270px;
  overflow: auto;
}

.z-orgDropdown {
  width: 320px;
}

.pz-dropdownSearch .input-group-text {
  border-radius: 2.25rem;
}

.pz-dropdownSearch .input-group-text {
  background-color: #ffffff;
}

.pz-dropdownSearch .form-control:focus {
  border-color: transparent;
}

.mainContent .sidebar .nav-link {
  color: #0642a6 !important;
  font-size: 14px;
  padding: 0.65rem 1rem;
}

.mainContent .sidebar .nav-link .nav-icon {
  color: #0642a6;
}
.mainContent .sidebar .nav a.nav-link.active {
  border-radius: 0 50px 50px 0;
  background-color: #0642a6;
  color: #fff !important;
}

.mainContent .sidebar .nav-link.active .nav-icon {
  color: #c0c1c1;
}

.mainContent .sidebar .nav-link:hover {
  color: #fff !important;
  background: #0642a6;
  border-radius: 0 50px 50px 0;
}

.mainContent .sidebar .nav-item {
  margin: 4px 0;
  width: inherit;
}
.mainContent .orgDropdownSidebar.nav-item {
  margin: 0;
}
.mainContent .sidebar-minimized .sidebar .nav-link:hover,
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background: #0642a6 !important;
  color: #fff !important;
  border-radius: 0 50px 50px 0;
}
.mainContent .sidebar .nav-dropdown.open {
  background: #e1e9f4 !important;
  margin: 5px 0 0 0;
}
.mainContent .sidebar .nav-dropdown.open .nav-link,
.mainContent .sidebar .nav-dropdown .nav-link {
  padding: 0.65rem 0.5rem 0.65rem 1.5rem;
}
.mainContent .sidebar .nav-dropdown.open .nav-link.nav-dropdown-toggle,
.mainContent .sidebar .nav-dropdown .nav-link.nav-dropdown-toggle {
  padding: 0.65rem 1rem;
}
@media only screen and (min-width: 1024px) {
  .mainContent .sidebar .nav {
    padding: 0px 20px 0px 0;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main {
    margin-left: 72px;
  }

  .sidebar-minimized .mainContent .sidebar .nav {
    padding: 0px;
  }
}
/* Custom css for sidebar end */
.media-body h5 {
  font-size: 17.5px;
}
.media-body span {
  font-size: 14px;
}

.MuiAutocomplete-popper {
  z-index: 9 !important;
}
.sidebar-minimized .sidebar .nav-link {
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    display: none;
    max-height: 1000px;
    background: #e1e9f4 !important;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    max-height: 255px;
    overflow-y: overlay;
  }

  .sidebar-minimized
    .sidebar
    .nav
    > .nav-dropdown:hover
    > .nav-dropdown-items::-webkit-scrollbar-track {
    background: linear-gradient(
      to right,
      var(--scroll-track-color) 0%,
      var(--scroll-track-color) 30%,
      var(--scroll-color) 60%,
      var(--body-bg-color) 61%,
      var(--body-bg-color) 100%
    );
  }

  .sidebar-minimized
    .sidebar
    .nav
    > .nav-dropdown:hover
    > .nav-dropdown-items::-webkit-scrollbar {
    width: 4px;
  }

  .sidebar-minimized
    .sidebar
    .nav
    > .nav-dropdown:hover
    > .nav-dropdown-items::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 4px;
  }
}
.divContainer .divider {
  margin: 0 -10px;
}
